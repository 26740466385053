<div
  class="grid w-full gap-x-2"
  [ngClass]="{
    'grid-cols-[repeat(auto-fit,minmax(100px,1fr))]':
      content().variation === 'large' || content().variation === 'small' || !content().variation
  }"
>
  @for (item of content().items; track item.title) {
    <div
      class="grid w-full text-center"
      [ngClass]="{
        'row-start-1 row-end-3 grid-rows-subgrid': content().variation === 'small' || !content().variation,
        'grid-flow-col grid-cols-[64px] gap-4': content().variation === 'large'
      }"
    >
      <cgx-icon class="mx-auto [--icon-size:48px]" [content]="item.icon"></cgx-icon>
      <cgx-paragraph
        class="text-balance text-[13px] leading-4"
        [ngClass]="{ '!text-wrap text-left text-base !leading-6': content().variation === 'large' }"
        [content]="{ text: item.title, ngTemplate: 'cgxParagraph' }"
      ></cgx-paragraph>
    </div>
  }
</div>
